import React from 'react';

import classNames from 'classnames';

import Icon from '@components/Icon';

type Props = {
  title: string;
  isArrowShown?: boolean;
  handleClick?: () => void;
};

const TitleLink: React.FC<Props> = ({
  title,
  isArrowShown = true,
  handleClick,
}) => {
  return (
    <div className="inline-block" onClick={handleClick}>
      <h2
        className={classNames('gray-600 flex items-center', {
          'cursor-pointer': !!handleClick,
        })}
      >
        <span className="mr-2">{title}</span>
        {isArrowShown && (
          <Icon
            name="arrows/right-thin"
            className="fill-gray-600 mt-1"
            size="19"
          />
        )}
      </h2>
    </div>
  );
};

export default TitleLink;
