// Libs
import React from 'react';

// Components
import TitleLink from '@presenters/web/components/TitleLink';

type Props = {
  title: string;
  titleWithArrow?: boolean;
  handleClick?: () => void;
  children: React.ReactNode;
};

const BlockWrapper: React.FC<Props> = ({
  title,
  titleWithArrow = true,
  handleClick,
  children,
}) => (
  <div className="mb-8">
    <TitleLink
      title={title}
      isArrowShown={titleWithArrow}
      handleClick={handleClick}
    />
    {children}
  </div>
);

export default BlockWrapper;
